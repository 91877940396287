export const tickerIntervalms = 50
const tickerInterval = 5

const landfillPerYear = 372999705
const daysInAYear = 365
const totalPerDay = landfillEveryDay()
const totalPerHour = landfillEveryHour()
const totalPerMinute = landfillEveryMinute()
const totalPerSecond = landfillEverySecond()

/**
 * Landfill Every Day
 * Divides the yearly landfill anount by 365.
 * Rounds up the decimal figure to nearest whole number.
 * Converts 'string' figure to a 'number'
 */
function landfillEveryDay() {
  const total = landfillPerYear / daysInAYear
  const round = total.toFixed(2)
  return parseFloat(round)
}

/**
 * Landfill Every Hour
 * Takes the total landfill perday and divides it by 24.
 * Rounds up the decimal figure to nearest whole number.
 * Converts 'string' figure to a 'number'
 * @param {number} perDay - Amount of landfill per day.
 */
function landfillEveryHour() {
  const total = totalPerDay / 24
  const round = total.toFixed(2)
  return parseFloat(round)
}

/**
 * Landfill Every Minute
 * Takes the total landfill per hour and divides it by 60
 * Rounds up the decimal figure to nearest whole number.
 * Converts 'string' figure to a 'number'
 * @param {number } perHour - Amount of landfill per minute.
 */
function landfillEveryMinute() {
  const total = totalPerHour / 60
  const round = total.toFixed(2)
  return parseFloat(round)
}

/**
 * Landfill Every Second
 * Takes the total landfill per minute and divides it by 60.
 */
export function landfillEverySecond() {
  const total = totalPerMinute / 60
  const round = total.toFixed(2)
  return parseFloat(round)
}

/**
 * Landfill Every 5 Seconds
 * Used for the setInterval() of the ticker
 * Takes the total landfill per second and multiplies it by 5.
 */

export function landfillEveryFiveSeconds() {
  const total = totalPerSecond * tickerInterval
  return total
}

/**
 * Calculate amount of seconds passed since the start date to an end date.
 * @param {date} start -
 * @param {date} end -
 */
function countDaysSinceBegining(start: Date, end: Date) {
  const totalDays = Math.ceil(
    ((end as any) - (start as any) + 1) / 86400000 // * 86400000 ms in 24 hrs
  )
  return totalDays
}

/**
 * Calculate real-time amount of seconds that have passed since midnight.
 */
function countRealTimeSecondsSinceMidnight() {
  const now = new Date()
  const then = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  )
  const total = now.getTime() - then.getTime()
  return total / 1000
}

export const landfillTicker = () => {
  const startDate = new Date(new Date().getFullYear(), 0, 1) // * 0, 1 = Month, Day
  const currentDate = new Date(new Date().getTime())

  const totalLandfillFromStartDateMidnight =
    countDaysSinceBegining(startDate, currentDate) * landfillEveryDay()
  const dailyAmountNow =
    (countRealTimeSecondsSinceMidnight() / 5) * landfillEveryFiveSeconds()
  const total = totalLandfillFromStartDateMidnight + dailyAmountNow
  return total
}

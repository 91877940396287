import { graphql } from "gatsby"
import React, { useState } from "react"

import Head from "../components/global/head"
import loadable from "@loadable/component"
import handleScrollTo from "../helpers/scroll"
import "../assets/styles/index.scss"
import ParallaxCache from "../components/interactive_education/parallax_cache"
import LandingHeroBanner from "../components/landing_hero_banner"
import LiveTracker from "../components/live_tracker"
import { ParallaxProvider } from "react-scroll-parallax"

import { Waypoint } from "react-waypoint"

import { useInView } from "react-intersection-observer"

const CtaBanner = loadable(() => {
  return import("../components/modules/cta_banner")
})
const OurProgramListings = loadable(() => {
  return import("../components/content/our_program_listings")
})

const ModuleRenderer = loadable(() => {
  return import("../components/modules")
})

const BackToTop = loadable(() => {
  return import("../components/buttons/back_to_top")
})

const ScrollDownCta = loadable(() => {
  return import("../components/buttons/scroll_down_cta")
})

const SideScreenCategory = loadable(() => {
  return import("../components/side_screen_category")
})
const InteractiveEducation = loadable(() => {
  return import("../components/interactive_education")
})
// ? Start App...
const Application = ({ data }) => {
  const hero = data.allDatoCmsHome.edges[0].node.modules.filter(
    (module) => module.__typename === "DatoCmsHero"
  )
  const [heroRef, heroInView] = useInView({
    threshold: 0.4,
  })
  const { ctaBannerModule } = data.allDatoCmsHome.edges[0].node
  const [showBackToTop, setShowBackToTop] = useState(false)

  // Handlers
  const handleHeroImage = () => {
    if (hero.length > 0) {
      return hero[0].backgroundImage
    } else {
      return null
    }
  }
  const handleHasImage = () => {
    if (hero[0].backgroundImage) {
      return true
    } else {
      return false
    }
  }
  const handleHasAltText = () => {
    if (hero[0].backgroundImage) {
      return hero[0].backgroundImage.alt
    } else {
      return ""
    }
  }

  const [tickerIntro, setTickerIntro] = useState(false)

  const handleModuleEnter = () => {
    setShowBackToTop(true)
    setTickerIntro(true)
  }

  const handleModuleLeave = () => {
    setShowBackToTop(false)
    setTickerIntro(false)
  }

  return (
    <>
      <Head
        title="Choose Tap | Join The Movement"
        url="https://www.choosetap.com.au"
        description="Choose Tap aims to improve the health and wellbeing of communities and the environment by promoting tap water as the best choice of hydration for all Australians."
        keywords="choose tap, tap water, drinking water, tap-finder, tap finder, single use plastic, plastic life cycle, be a refiller, refillers welcome, be a refiller not a landfiller, australian tap water, tap water v bottled water, tap water verses bottled water"
        bodyClassname="body__home"
      />
      {showBackToTop && (
        <BackToTop onClick={handleScrollTo("top")} isVisible={!heroInView} />
      )}
      <ScrollDownCta
        onClick={handleScrollTo("live-tracker")}
        className={`${heroInView ? "util__show" : "util__hide"}`}
      />

      <ParallaxProvider>
        <div className="pages__home" id="top">
          <div ref={heroRef} style={{ position: "relative", zIndex: 20 }}>
            <LandingHeroBanner
              title={hero[0].heroTitle}
              shortDescription={hero[0].heroText}
              image={handleHeroImage()}
              isLandingInstance={true}
              hasImage={handleHasImage()}
              themeColor="navy"
              altText={handleHasAltText()}
            />
          </div>

          <a id="live-tracker" />
          <Waypoint
            onEnter={() => handleModuleEnter()}
            onLeave={() => handleModuleLeave()}
          >
            <div>
              <LiveTracker isVisible={tickerIntro} />

              <ParallaxCache />
              <InteractiveEducation />

              <section
                id="how-we-are-helping"
                className="how-we-are-helping util__background--white util__padding-top--80"
              >
                <div className="seperator" />
                <SideScreenCategory aligned="left" label="Programs" />
                <SideScreenCategory aligned="right" label="Programs" />
                <h2 className="typography__display--7 util__text-align--center   util__margin-bottom--50  typography__text-trans--none util__width--80-per util__margin--0-auto">
                  How we're helping
                </h2>
                <OurProgramListings />
              </section>
              <section className="all-modules util__background--white">
                <ModuleRenderer
                  isLandingInstance={true}
                  modules={data.allDatoCmsHome.edges[0].node.modules}
                />
                {ctaBannerModule.length > 0 && (
                  <CtaBanner
                    isFooterInstance={true}
                    title={ctaBannerModule[0].title}
                    ctaLabel={ctaBannerModule[0].ctaLabel}
                    ctaLink={ctaBannerModule[0].ctaLink}
                    ctaPageUrl={ctaBannerModule[0].ctaPageUrl}
                    image={ctaBannerModule[0].backgroundImage}
                  />
                )}
              </section>
            </div>
          </Waypoint>
        </div>
      </ParallaxProvider>
    </>
  )
}
export default Application

export const query = graphql`
  query {
    allDatoCmsHome {
      edges {
        node {
          ctaBannerModule {
            __typename
            title
            backgroundImage {
              url
              alt
            }
            ctaLink {
              __typename
              ... on DatoCmsPost {
                slug
              }
              __typename
              ... on DatoCmsProgram {
                slug
              }
            }
            ctaLabel
            ctaPageUrl
          }
          modules {
            __typename
            ... on DatoCmsHero {
              id
              heroTitle
              heroText
              backgroundImage {
                url
                alt
              }
            }
            ... on DatoCmsContentBlock {
              id
              title
              bodyCopy
            }
            ... on DatoCmsVideoBlock {
              youtubeUrl
            }
            ... on DatoCmsImageTextBlock {
              __typename
              id
              alignRight
              bodyCopy
              isRootStoryNews
              isRootProgram
              ctaPageUrl
              ctaLabel
              ctaLink {
                ... on DatoCmsProgramPartnerChild {
                  slug
                }
                ... on DatoCmsProgram {
                  slug
                }
                ... on DatoCmsPost {
                  slug
                }
              }
              title
              image {
                alt
                url
              }
            }
            ... on DatoCmsTwoColumnContentBlock {
              column1Title
              column1Body
              column2Title
              column2Body
            }
          }
        }
      }
    }
    allDatoCmsProgram {
      edges {
        node {
          id
          programTitle
          programCtaLabel
          slug
          modules {
            __typename
            ... on DatoCmsBodyCopy {
              bodyCopyNode {
                childMarkdownRemark {
                  excerpt(pruneLength: 180)
                }
              }
            }
            ... on DatoCmsHero {
              backgroundImage {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`

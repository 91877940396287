import React from "react"
import { useController } from "react-scroll-parallax"

const ParallaxCache = () => {
  if (typeof window !== "undefined") {
    const { parallaxController } = useController()
    React.useLayoutEffect(() => {
      console.log("layouting")
      const handler = () => parallaxController.update()
      window.addEventListener("load", handler)
      return () => window.removeEventListener("load", handler)
    }, [parallaxController])
  }

  return null
}

export default ParallaxCache

import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import trackerStyles from "../../assets/styles/modules/live_tracker.module.scss"

import ContentBoundary from "../content/boundary"
import PrimaryCta, { themes } from "../buttons/primary_cta"

import {
  landfillEverySecond,
  landfillTicker,
  tickerIntervalms,
} from "./functions"

const facts = [
  {
    title: "1,021,917",
    description: "bottles every day",
  },
  {
    title: "1,440",
    description: "bottles every minute",
  },
  {
    title: "140",
    description: "Olympic sized swimming pools filled every year ",
  },
]

const handleTickerFormat = (number) => {
  const n = number.toFixed()
  const nn = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return nn
}

const LiveTracker = ({ isVisible }) => {
  const [initTick, setInitTick] = useState(false)
  const [hydratedTick, setHydratedTick] = useState(false)

  useEffect(() => {
    setInitTick(false)
    setHydratedTick(false)
    if (isVisible) {
      const timer = setTimeout(() => {
        setInitTick(false)
        setHydratedTick(true)
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    } else {
      return
    }
  }, [isVisible])

  const [ticker, setTicker] = useState(landfillTicker())

  useEffect(() => {
    const timer = setInterval(() => {
      setTicker(ticker + landfillEverySecond() / 5)
    }, tickerIntervalms)

    return () => {
      clearInterval(timer)
    }
  }, [ticker, landfillEverySecond()])

  const year = new Date()

  return (
    <section id="live-tracker" className={trackerStyles.wrapper}>
      <div id="live-tracker" className={trackerStyles.container}>
        <ContentBoundary className={trackerStyles.content}>
          <div>
            <h2 className="typography__display--4 typography__color--white util__margin-bottom--25 typography__ff--quatro util__text-align--center">
              Since 01 January {year.getFullYear()}
            </h2>
            <div
              className={`${trackerStyles.tickerContainer} ${
                !isVisible
                  ? "util__hide util__hide-transition"
                  : "util__show util__show-transition"
              } util__margin--0-auto`}
            >
              <h2
                className={`${trackerStyles.ticker} typography__display--17 typography__color--white util__margin-bottom--25`}
              >
                {handleTickerFormat(ticker)}
              </h2>
            </div>
            <div style={{ maxWidth: "300px", margin: "0 auto" }}>
              <h2 className="typography__display--4 typography__color--white util__margin-bottom--50 typography__text-trans--none  util__text-align--center typography__ff--quatro  util__text-align--center">
                single-use plastic bottles have been sent to landfill
              </h2>
            </div>

            <div className={trackerStyles.container__gridInner}>
              {facts.map(({ title, description }) => {
                return (
                  <div key={title} className={trackerStyles.factCircleWrapper}>
                    <div className={`${trackerStyles.factCircle}`}>
                      <div>
                        <h3 className="typography__color--white typography__display--5">
                          {title}
                        </h3>
                        <h3 className="typography__color--white typography__display--3 typography__ff--flamaMedium typography__text-trans--none">
                          {description}
                        </h3>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

            <div className="util__text-align--center">
              <PrimaryCta
                label="Get involved"
                theme={themes.white}
                to="/what-we-do"
              />
            </div>
          </div>
        </ContentBoundary>
      </div>
    </section>
  )
}

export default LiveTracker

import scrollToElement from "scroll-to-element"

const handleScrollTo = (id) => {
  if (typeof document !== "undefined") {
    const ele = document.getElementById(id)

    return (e) => {
      e.preventDefault()

      scrollToElement(ele, {
        ease: "inOutExpo",
        duration: 1200,
        offset: window.innerWidth <= 768 ? -120 : 0,
      })
    }
  }
}

export default handleScrollTo

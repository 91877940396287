import React, { useEffect, useState } from "react"
import { HeroIntroBannerProps } from "../modules/types"

import { Parallax } from "react-scroll-parallax"

import heroImageStyles from "../../assets/styles/modules/banners/hero.module.scss"
import styles from "../../assets/styles/modules/banners/landing_hero.module.scss"

import { Tldr } from "../svgs"

const { defaultWidth, heroImage } = heroImageStyles

const LandingHeroBanner = ({
  altText,
  image,
  shortDescription,
  themeColor,
  title,
}: HeroIntroBannerProps) => {
  const [bg1YCords, setBg1YCords] = useState([50, 0])
  const [bg2YCords, setBg2YCords] = useState([130, 20])

  return (
    <section
      className={`heroBanner navy heroBanner--with-image ${styles.landingHeroBanner} util__margin-bottom--0`}
    >
      <div className={`heroBanner__content--row ${styles.landingHeroContent}`}>
        <h1 className="typography__display--7  typography__text-trans--none  heroBanner__title  typography__color--white">
          {title}
        </h1>
        <Tldr />
        <p
          className={`typography__display--3  heroBanner__shortDescription  ${themeColor ===
            "blue" && "typography__color--white"} typography__text-trans--none`}
        >
          {shortDescription}
        </p>
        <div className={`${styles.heroImageWrapper}`}>
          <img
            src={image.url}
            alt={altText}
            className={`${heroImage} ${defaultWidth}`}
          />
        </div>
      </div>

      <Parallax
        y={bg1YCords}
        tagOuter="BackgroundImage1"
        className={styles.backgroundImage}
      >
        <div className={styles.backgroundImageOne} />
      </Parallax>
    </section>
  )
}

export default LandingHeroBanner
